
import { Options, Vue } from 'vue-class-component';
import { mapGetters } from 'vuex';
import AppInput from '@/components/AppInput.vue';
import AppButton from '@/components/AppButton.vue';
import { FixedAssetModel } from '@/types/forms/fixed-assets';
import apiFixedAssets from '@/repositories/api/fixed-assets';
import AppArrowBigRightIcon from '@/assets/icons/AppArrowBigRightIcon.vue';
import { digitsMask } from '@/app.config';
import AppAccordion from '@/components/Accordion/AppAccordion.vue';
import AppAccordionItem from '@/components/Accordion/AppAccordionItem.vue';
import ConfirmationModal from '@/components/ConfirmationModal.vue';
import { ResourceCollectionRequest } from '@/types/api/common';
import { SubdivisionsOptions } from '@/store/api/Subdivision';
import AppSelect from '@/components/AppSelect.vue';
import { fixedAssetInlineAdditionalInfo } from '@/utils/fixed-assets';
import printFixedAssetsQrs from '@/utils/print-fixed-assets-qrs';

type FilterForm = {
  name: string,
  codeOrInventoryNumber?: number,
}

@Options({
  name: 'MassQrCodePrint',
  emits: ['hide'],
  components: {
    AppSelect,
    ConfirmationModal,
    AppAccordionItem,
    AppAccordion,
    AppArrowBigRightIcon,
    AppButton,
    AppInput,
  },
  computed: {
    ...mapGetters({
      subdivisionsOptions: 'subdivisionsOptions',
    }),
  },
})
export default class MassQrCodePrint extends Vue {
  readonly fixedAssetInlineAdditionalInfo = fixedAssetInlineAdditionalInfo;
  readonly subdivisionsOptions!: SubdivisionsOptions;
  readonly digitsMask = digitsMask;
  fixedAssets: FixedAssetModel[] | null = null;
  emptyFilterForm: FilterForm = {
    name: '',
    codeOrInventoryNumber: undefined,
  };
  fullListFilterForm: FilterForm = { ...this.emptyFilterForm };
  selectedListFilterForm: FilterForm = { ...this.emptyFilterForm };
  showBackConfirmModal = false;
  subdivisionId?: string;
  fullList: FixedAssetModel[] | null = null;
  selectedList: FixedAssetModel[] = [];
  isLoading = false;

  get fixedAssetsExists(): boolean {
    return (this.fixedAssets?.length || 0) > 1;
  }

  get fullListFiltered(): FixedAssetModel[] {
    return this.filterItems(this.fullList!, this.fullListFilterForm);
  }

  get fullListFilteredCount(): number {
    return this.fullListFiltered.length;
  }

  get selectedListFiltered(): FixedAssetModel[] {
    return this.filterItems(this.selectedList, this.selectedListFilterForm);
  }

  get selectedListCount(): number {
    return this.selectedList.length;
  }

  get selectedListFilteredCount(): number {
    return this.selectedListFiltered.length;
  }

  filterItems(items: FixedAssetModel[], filter: FilterForm): FixedAssetModel[] {
    const nameFilter = filter.name.trim().toLowerCase();
    const codeOrInventoryNumberFilter = filter.codeOrInventoryNumber?.toString();
    return items.filter((e) => {
      let keep = true;
      if (nameFilter) {
        keep = e.name.toLowerCase().search(nameFilter) !== -1;
      }
      if (keep && codeOrInventoryNumberFilter) {
        keep = `${e.code} ${e.inventoryNumber}`.search(codeOrInventoryNumberFilter) !== -1;
      }
      return keep;
    });
  }

  get formWasChanged(): boolean {
    return Boolean(this.selectedListCount);
  }

  async fetchFixedAssets() {
    try {
      this.isLoading = true;
      this.selectedList = [];
      this.fullList = null;
      const response = await apiFixedAssets.getList(new ResourceCollectionRequest({
        filter: {
          subdivisionId: this.subdivisionId,
        },
        perPage: 0,
      }));
      this.fixedAssets = response.data.data
        .map((e) => this.$helpers.resolveDataFromApi<FixedAssetModel>(e));
      this.fullList = Object.assign(this.fixedAssets);
      this.isLoading = false;
    } catch (e) {
      this.$store.dispatch('showAlertError', 'Произошла ошибка при загрузке');
    }
  }

  isFixedAssetChecked(fixedAsset: FixedAssetModel): boolean {
    return this.selectedList.findIndex((e) => e.id === fixedAsset.id) !== -1;
  }

  switchFixedAsset(add: boolean, fixedAsset: FixedAssetModel) {
    const index = this.selectedList.findIndex((e) => e.id === fixedAsset.id);
    if (add) {
      if (index !== -1) return;
      this.selectedList.push(fixedAsset);
    } else {
      if (index === -1) return;
      this.selectedList = this.selectedList.filter((e) => e.id !== fixedAsset.id);
    }
  }

  resetSearch(selectedSearchForm = false) {
    if (selectedSearchForm) {
      if (
        this.selectedListFilterForm.codeOrInventoryNumber
          === this.emptyFilterForm.codeOrInventoryNumber
        && this.selectedListFilterForm.name === this.emptyFilterForm.name
      ) return;
      this.selectedListFilterForm = { ...this.emptyFilterForm };
    } else {
      if (
        this.fullListFilterForm.codeOrInventoryNumber === this.emptyFilterForm.codeOrInventoryNumber
        && this.fullListFilterForm.name === this.emptyFilterForm.name
      ) return;
      this.fullListFilterForm = { ...this.emptyFilterForm };
    }
  }

  onBackBtnClick() {
    if (this.formWasChanged) {
      this.showBackConfirmModal = true;
    } else {
      this.back();
    }
  }

  unselectAll() {
    this.selectedList = [];
  }

  selectAll() {
    this.unselectAll();
    this.selectedList = this.fullList!;
  }

  back() {
    this.showBackConfirmModal = false;
    this.$emit('hide');
  }

  printQrCodes() {
    this.isLoading = true;
    printFixedAssetsQrs(
      this.selectedList,
      {
        onError: (error: any) => {
          this.$store.dispatch('showAlertError', 'Произошла ошибка при распечатке');
          this.isLoading = false;
        },
        onLoadingStart: () => {
          this.isLoading = true;
        },
        onLoadingEnd: () => {
          this.isLoading = false;
        },
      },
    );
  }
}
