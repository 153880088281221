import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteMeta,
  RouteRecordRaw,
} from 'vue-router';
import Cookie from 'js-cookie';
import Login from '../views/Login.vue';
import CabinetLayout from '@/layouts/CabinetLayout.vue';
import UsersList from '@/views/users/UsersList.vue';
import UsersForm from '@/views/users/UsersForm.vue';
import QrPrint from '@/views/qr-print/QrPrint.vue';
import { UserRole } from '@/types/forms/users';
import store from '@/store';
import { AppRouter403Error } from '@/types/app';
import { tokenCookieKey } from '@/app.config';
import NotFound404 from '@/views/errors/NotFound404.vue';

declare interface AppRouteMeta extends RouteMeta {
  roles?: UserRole[],
  title?: string,
}

type AppRouteRecordRaw = RouteRecordRaw & {
  meta?: AppRouteMeta,
};

declare interface AppRouteLocationNormalized extends RouteLocationNormalized {
  meta: AppRouteMeta,
}

const routes: AppRouteRecordRaw[] = [
  {
    path: '/auth/login',
    name: 'Login',
    component: Login,
    meta: { title: 'Вход' },
  },
  {
    path: '/',
    redirect(to) {
      const profile = store.getters.myProfile;
      if (!profile) return '/auth/login';
      if (profile.role === 'admin') return '/users';
      return '/qr-print';
    },
  },
  {
    path: '/users',
    component: CabinetLayout,
    meta: {
      roles: ['admin'],
    },
    children: [
      {
        path: '/users',
        component: UsersList,
        meta: { title: 'Пользователи' },
      },
      {
        path: '/users/show/:userId',
        component: UsersForm,
        meta: { title: 'Просмотр пользователя' },
      },
      {
        path: '/users/edit/:userId',
        component: UsersForm,
        meta: { title: 'Редактировать пользователя' },
      },
      {
        path: '/users/create',
        component: UsersForm,
        meta: { title: 'Создать пользователя' },
      },
    ],
  },
  {
    path: '/qr-print',
    component: CabinetLayout,
    meta: {
      roles: ['accountant'],
    },
    children: [
      {
        path: '/qr-print',
        component: QrPrint,
        meta: { title: 'Печать QR' },
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    meta: { title: 'Страница не найдена' },
    component: NotFound404,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to: AppRouteLocationNormalized, from, next) => {
  let profile = store.getters.myProfile;
  const hasToken = !!Cookie.get(tokenCookieKey);
  const toLoginPage = to.path.includes('/auth/login');
  if (hasToken && !profile) {
    await store.dispatch('initProfile');
    profile = store.getters.myProfile;
  }
  if (toLoginPage && profile) {
    next('/');
    return;
  }
  if (!toLoginPage && !profile) {
    next('/auth/login');
    return;
  }
  if (to.meta?.roles) {
    if (!to.meta.roles.includes(profile.role)) {
      next(new AppRouter403Error());
      return;
    }
  }

  const title = to.meta?.title || 'Панель управления';
  document.title = `Diaverum | ${title}`;

  next();
});

export default router;
