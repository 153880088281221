import Validator from 'validatorjs';

const messages = Validator.getMessages('ru');

messages.required = 'Поле обязательно для заполнения.';
messages.required_with = messages.required;
messages.required_if = messages.required;
messages.min = 'Количество символов должно быть не менее :min.';
messages['same.passwordConfirmation'] = 'Пароли не совпадают';
messages['size.login'] = 'Номер телефона введен некорректно';

Validator.setMessages('ru', messages);
Validator.useLang('ru');
