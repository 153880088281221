import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CIcon = _resolveComponent("CIcon")!
  const _component_AppSidebarNavItem = _resolveComponent("AppSidebarNavItem")!
  const _component_CNavGroup = _resolveComponent("CNavGroup")!
  const _component_CSidebarNav = _resolveComponent("CSidebarNav")!

  return (_openBlock(), _createBlock(_component_CSidebarNav, null, {
    default: _withCtx(() => [
      (_ctx.profile)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.filteredNav, (navItem) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: navItem }, [
              (navItem.items)
                ? (_openBlock(), _createBlock(_component_CNavGroup, {
                    key: 0,
                    visible: _ctx.isActiveItem(navItem)
                  }, {
                    togglerContent: _withCtx(() => [
                      _createVNode(_component_CIcon, {
                        "custom-class-name": "nav-icon",
                        name: navItem.icon
                      }, null, 8, ["name"]),
                      _createTextVNode(" " + _toDisplayString(navItem.name), 1)
                    ]),
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navItem.items, (navItemChild) => {
                        return (_openBlock(), _createBlock(_component_AppSidebarNavItem, {
                          "nav-item": navItemChild,
                          key: navItemChild
                        }, null, 8, ["nav-item"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["visible"]))
                : (_openBlock(), _createBlock(_component_AppSidebarNavItem, {
                    key: 1,
                    "nav-item": navItem
                  }, null, 8, ["nav-item"]))
            ], 64))
          }), 128))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}