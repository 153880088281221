import { AxiosError } from 'axios';
import { snakeCase } from 'change-case';

export const enum ResponseStatus {
  UNAUTHORIZED = 401,
  VALIDATION_ERROR = 422,
  TOO_MANY_REQUESTS = 429,
}

export const enum ResponseErrorTypes {
  AUTH_LOGIN_CREDENTIALS_INVALID = 'credentials_invalid',
}

export type ValidationError<T> = {
  [K in keyof T]?: string[]
}

export interface ResponseErrorData<T = any, E = ResponseErrorTypes> {
  error_message: string;
  error_type: E;
  validation_errors?: ValidationError<T>;
}

export type ResponseError<T, E = ResponseErrorTypes> = AxiosError<ResponseErrorData<T, E>>

export interface DataResponse<T> {
  data: T;
}

export interface UnprocessableContentResponse {
  error_type: string,
  validation_errors: {
    [key: string]: string[]
  }
}

export interface PaginationResponse<T> {
  data: T[];
  links: {
    first: string
    last: string
    prev: string | null
    next: string | null
  };
  meta: {
    from: number
    to: number
    current_page: number
    last_page: number
    per_page: number
    total: number
    path: string
  };
}

interface ResourceCollectionRequestOpts<T> {
  page?: number,
  relations?: string[],
  filter?: T,
  perPage?: number,
}

export class ResourceCollectionRequest<T> {
  static defaultOpts: ResourceCollectionRequestOpts<undefined> = {
    page: undefined,
    relations: undefined,
    filter: undefined,
    perPage: undefined,
  };
  private snakeCaseFilterIgnore: string[] = [];

  public addSnakeCaseFilterIgnore(ignore: string) {
    this.snakeCaseFilterIgnore.push(ignore);
  }

  public opts: ResourceCollectionRequestOpts<T>;

  constructor(opts: ResourceCollectionRequestOpts<T>) {
    this.opts = { ...ResourceCollectionRequest.defaultOpts, ...opts };
  }

  get forUrl(): string {
    const query: string[] = [];
    if (this.opts.perPage !== undefined && this.opts.perPage !== null) {
      query.push(`per_page=${this.opts.perPage}`);
    }
    if (this.opts.page) {
      query.push(`page=${this.opts.page}`);
    }
    if (this.opts.filter) {
      Object.entries(this.opts.filter).forEach((e) => {
        if (e[1] !== undefined && e[1] !== null && e[1].toString().trim().length > 0) {
          const name = snakeCase(e[0]);
          let val: any = e[1];
          if (typeof val === 'string' && !this.snakeCaseFilterIgnore.includes(name)) {
            val = snakeCase(val);
          }
          query.push(`filter[${name}]=${val}`);
        }
      });
    }
    if (this.opts.relations) {
      this.opts.relations.forEach((e) => {
        query.push(`relations[]=${e}`);
      });
    }

    return encodeURI(query.join('&'));
  }
}
