
import { Options, Vue } from 'vue-class-component';
import { PropType } from 'vue';

@Options({
  name: 'AppFormFeedback',
  props: {
    invalid: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      default: false,
    },
    listText: {
      type: Array as PropType<string[]>,
    },
  },
})
export default class AppFormFeedback extends Vue {
}
