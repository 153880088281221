
import { Options, Vue } from 'vue-class-component';
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/vue';
import { useRoute } from 'vue-router';
import { mapGetters } from 'vuex';
import nav from '@/_nav';
import AppSidebarNavItem from '@/components/AppSidebarNavItem.vue';
import { UserModel } from '@/types/forms/users';

@Options({
  name: 'AppSidebarNav',
  computed: mapGetters({
    profile: 'myProfile',
  }),
  components: {
    CNavItem,
    CNavGroup,
    CNavTitle,
    AppSidebarNavItem,
  },
})
export default class AppSidebarNav extends Vue {
  readonly profile!: UserModel | null;
  nav = nav;
  route = useRoute();

  get filteredNav() {
    return this.nav.filter((e) => !e.userRoles || e.userRoles.includes(this.profile!.role!));
  }

  normalizePath(path: string) {
    return decodeURI(path)
      .replace(/#.*$/, '')
      .replace(/(index)?\.(html)$/, '');
  }

  isActiveLink(link: any) {
    if (link === undefined) {
      return false;
    }

    if (this.route.hash === link) {
      return true;
    }

    const currentPath = this.normalizePath(this.route.path);
    const targetPath = this.normalizePath(link);

    return currentPath === targetPath;
  }

  isActiveItem(item: any) {
    if (this.isActiveLink(item.to)) {
      return true;
    }

    if (item.items) {
      return item.items.some((child: any) => this.isActiveItem(child));
    }

    return false;
  }
}
