import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "position-fixed start-0 top-0 bottom-0 end-0 w-100 h-100 d-flex flex-row justify-content-center align-items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_alert = _resolveComponent("app-alert")!
  const _component_Forbidden403 = _resolveComponent("Forbidden403")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_CSpinner = _resolveComponent("CSpinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_alert),
    (_ctx.forbidden403)
      ? (_openBlock(), _createBlock(_component_Forbidden403, {
          key: 0,
          onOnExitClick: _ctx.goToMainPage
        }, null, 8, ["onOnExitClick"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.routerReady)
            ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
            : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_CSpinner, { color: "primary" })
              ]))
        ], 64))
  ], 64))
}