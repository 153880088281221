
import { Options, Vue } from 'vue-class-component';
import { generate as generatePassword } from 'generate-password';
import { mapGetters } from 'vuex';
import Validator, { Errors, Rules } from 'validatorjs';
import _ from 'lodash';
import AppInput from '@/components/AppInput.vue';
import apiUsers from '@/repositories/api/users';
import apiIndividuals from '@/repositories/api/individuals';
import AppButton from '@/components/AppButton.vue';
import AppSelect from '@/components/AppSelect.vue';
import { phoneMask, iinMask } from '@/app.config';
import { UserModel, UserRole, userRolesOptions } from '@/types/forms/users';
import { SubdivisionsOptions } from '@/store/api/Subdivision';
import AppFormFeedback from '@/components/AppFormFeedback.vue';
import ConfirmationModal from '@/components/ConfirmationModal.vue';
import { ResourceCollectionRequest } from '@/types/api/common';
import { IndividualModel } from '@/types/forms/individuals';

interface UserForm extends UserModel {
  password: string | null,
  passwordConfirmation: string | null,
  iin: string | null,
}

@Options({
  name: 'UsersForm',
  components: {
    ConfirmationModal,
    AppFormFeedback,
    AppSelect,
    AppButton,
    AppInput,
  },
  computed: {
    ...mapGetters({
      subdivisionsOptions: 'subdivisionsOptions',
    }),
    userFormRules() {
      return {
        firstName: ['required', 'min:2'],
        lastName: ['required', 'min:2'],
        login: ['required', 'size:12'],
        role: ['required'],
        subdivisionId: ['required_if:role,responsiblePerson', 'required_if:role,accountant', 'string'],
        password: [this.isEdit ? 'present' : 'required', 'string', 'min:8'],
        passwordConfirmation: [this.isEdit ? 'required_with:password' : 'required', 'same:password'],
      };
    },
  },
  watch: {
    'userForm.iin': function () {
      this.onIinChange();
    },
  },
})
export default class UsersForm extends Vue {
  readonly phoneMask = phoneMask;
  readonly iinMask = iinMask;
  readonly userRolesOptions = userRolesOptions;
  readonly subdivisionsOptions!: SubdivisionsOptions;
  readonly userFormRules!: Rules;
  saving = false;
  isShow = true;
  validationErrors: Errors | null = null;
  initialUserForm: UserForm = this.emptyUserForm();
  userForm: UserForm = this.emptyUserForm();
  isLoading = true;
  hiddenPassword = true;
  showBackConfirmModal = false;
  searchingByIinFailed = false;
  searchingByIin = false;

  created(): void {
    const userId = +this.$route.params.userId || null;
    this.isShow = this.$route.path.indexOf('/show/') !== -1;
    if (userId) {
      this.$store.commit(
        'SET_HEADER_TITLE',
        this.isShow ? 'Просмотр пользователя' : 'Редактирование пользователя',
      );
      this.loadUser(userId);
    } else {
      this.isLoading = false;
      this.$store.commit('SET_HEADER_TITLE', 'Создание пользователя');
    }
  }

  onRoleChanged(event: InputEvent) {
    if (event.target == null) return;
    this.userForm.role = (event?.target as HTMLInputElement).value as UserRole;
    this.clearForm(true);
  }

  get isEdit(): boolean {
    return Boolean(this.userForm.id);
  }

  get formWasChanged(): boolean {
    return !_.isEqual(this.initialUserForm, this.userForm);
  }

  emptyUserForm(): UserForm {
    return {
      id: 0,
      iin: null,
      email: '',
      firstName: '',
      lastName: '',
      login: '',
      blocked: false,
      role: 'responsiblePerson',
      password: null,
      passwordConfirmation: null,
      subdivisionId: null,
      individual: null,
    };
  }

  async loadUser(userId: number) {
    try {
      const response = await apiUsers.get(userId);
      this.userForm = {
        password: null,
        passwordConfirmation: null,
        iin: null,
        ...this.$helpers.resolveDataFromApi<UserModel>(response.data, {
          transformValues: ['role'],
        }),
      };
      this.initialUserForm = { ...this.userForm };
    } catch (e) {
      this.$store.dispatch('showAlertError', 'Произошла ошибка при загрузке');
    } finally {
      this.isLoading = false;
    }
  }

  generatePassword() {
    this.userForm.password = generatePassword({
      length: 8,
    });
    this.userForm.passwordConfirmation = this.userForm.password;
  }

  async save() {
    try {
      this.saving = true;
      this.validationErrors = null;
      const validation = new Validator(this.userForm, this.userFormRules);
      if (validation.fails()) {
        this.validationErrors = validation.errors;
        return;
      }

      const data = this.$helpers.resolveDataForApi(this.userForm, {
        transformValues: ['role'],
      });
      const response = this.isEdit
        ? await apiUsers.put(this.userForm.id, data)
        : await apiUsers.create(data);
      if ((this.isEdit && response.status !== 200) || (!this.isEdit && response.status !== 201)) {
        throw new Error();
      }
      this.$router.push('/users?success-save');
    } catch (e) {
      console.error(e);
      this.$store.dispatch('showAlertError', 'Произошла ошибка при обработке данных');
    } finally {
      this.saving = false;
    }
  }

  goBack() {
    this.$router.push('/users');
  }

  onBackBtnClick() {
    if (!this.formWasChanged) {
      this.goBack();
      return;
    }
    this.showBackConfirmModal = true;
  }

  clearForm(clearIin = false) {
    this.userForm.firstName = '';
    this.userForm.lastName = '';
    if (clearIin) {
      this.userForm.iin = null;
    }
  }

  async onIinChange() {
    this.searchingByIinFailed = false;
    if (this.userForm.firstName.length > 0) {
      this.clearForm();
    }
    if (this.userForm.iin?.length !== 12) return;
    try {
      this.searchingByIin = true;
      const response = await apiIndividuals.getList(new ResourceCollectionRequest({
        filter: {
          iin: this.userForm.iin,
        },
      }));
      if (response.data.data.length === 0) {
        this.searchingByIinFailed = true;
      } else {
        const individual = this.$helpers.resolveDataFromApi<IndividualModel>(response.data.data[0]);
        const nameParts = individual.name.split(' ');
        this.userForm.firstName = nameParts[0];
        this.userForm.lastName = nameParts[1] || 'Не указана';
      }
    } catch (e) {
      console.error(e);
      this.$store.dispatch('showAlertError', 'Произошла ошибка при обработке данных');
    } finally {
      this.searchingByIin = false;
    }
  }
}
