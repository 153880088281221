
import { Options, Vue } from 'vue-class-component';
import AppInput from '@/components/AppInput.vue';
import AppButton from '@/components/AppButton.vue';
import { FixedAssetModel } from '@/types/forms/fixed-assets';
import apiFixedAssets from '@/repositories/api/fixed-assets';
import { ResourceCollectionRequest } from '@/types/api/common';
import { digitsMask } from '@/app.config';
import { fixedAssetInlineAdditionalInfo } from '@/utils/fixed-assets';
import printFixedAssetsQrs from '@/utils/print-fixed-assets-qrs';

@Options({
  name: 'FastQrCodePrint',
  components: {
    AppButton,
    AppInput,
  },
})
export default class FastQrCodePrint extends Vue {
  readonly digitsMask = digitsMask;
  readonly fixedAssetInlineAdditionalInfo = fixedAssetInlineAdditionalInfo;
  fixedAssets: FixedAssetModel[] | null = null;
  fixedAssetsLoading = false;
  isPrintLoading = false;
  showedFixedAsset: FixedAssetModel | null = null;
  searchFixedAssetForm = {
    code: null as number | null,
    loading: false,
    errors: null as string[] | null,
  };

  get searchFixedAssetFormEmpty(): boolean {
    return !this.searchFixedAssetForm.code || this.searchFixedAssetForm.code.toString() === '0';
  }

  created() {
    this.fetchFixedAssets();
  }

  async fetchFixedAssets() {
    try {
      this.fixedAssetsLoading = true;
      const response = await apiFixedAssets.getList(new ResourceCollectionRequest({
        perPage: 8,
        relations: ['subdivision'],
      }));
      this.fixedAssets = response.data.data.map(
        (e) => this.$helpers.resolveDataFromApi<FixedAssetModel>(e),
      );
    } catch (e) {
      this.$store.dispatch('showAlertError', 'Произошла ошибка при загрузке');
    } finally {
      this.fixedAssetsLoading = false;
    }
  }

  async searchFixedAsset() {
    try {
      this.searchFixedAssetForm.errors = null;
      this.searchFixedAssetForm.loading = true;
      const response = await apiFixedAssets.getList(new ResourceCollectionRequest({
        filter: {
          code: this.searchFixedAssetForm.code!,
        },
        relations: ['subdivision'],
      }));
      if (response.data.data.length === 0) {
        this.searchFixedAssetForm.errors = ['Основное средство с таким номером не найдено'];
        return;
      }
      const result = response.data.data.map(
        (e) => this.$helpers.resolveDataFromApi<FixedAssetModel>(e),
      );
      this.showQrCode(result[0]);
    } catch (e) {
      console.error(e);
      this.$store.dispatch('showAlertError', 'Произошла ошибка при загрузке');
    } finally {
      this.searchFixedAssetForm.loading = false;
    }
  }

  showQrCode(fixedAsset: FixedAssetModel) {
    this.showedFixedAsset = fixedAsset;
  }

  hideQrCode() {
    this.showedFixedAsset = null;
  }

  printQrCode() {
    if (!this.showedFixedAsset) return;
    this.isPrintLoading = true;
    printFixedAssetsQrs(
      [this.showedFixedAsset],
      {
        onError: (error: any) => {
          this.$store.dispatch('showAlertError', 'Произошла ошибка при распечатке');
          this.isPrintLoading = false;
        },
        onLoadingStart: () => {
          this.isPrintLoading = true;
        },
        onLoadingEnd: () => {
          this.isPrintLoading = false;
        },
      },
    );
  }
}
