import Inputmask from 'inputmask';

export const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
export const tokenCookieKey = 'c_token';
export const tokenCookieExpiresInDays = 365;

export const currencyMaskOptions: Inputmask.Options = {
  alias: 'currency',
  rightAlign: false,
  groupSeparator: ' ',
  inputmode: 'numeric',
  numericInput: true,
  suffix: '₸',
  radixPoint: ',',
  digits: 0,
  max: 9999999,
};
export const currencyMask = new Inputmask(currencyMaskOptions);

export const phoneMaskOptions: Inputmask.Options = {
  mask: '+7 (999) 999 99 99',
  placeholder: '_',
  inputmode: 'numeric',
  onUnMask(maskedValue: string, unmaskedValue: string) {
    return unmaskedValue.length > 0 ? `+7${unmaskedValue}` : '';
  },
};
export const phoneMask = new Inputmask(phoneMaskOptions);
export const maskPhoneString = function (phone: string): string {
  return Inputmask.format(phone, phoneMaskOptions);
};

export const iinMaskOptions: Inputmask.Options = {
  mask: '999999999999',
  placeholder: '',
  inputmode: 'numeric',
};
export const iinMask = new Inputmask(iinMaskOptions);

export const numberMaskOptions: Inputmask.Options = {
  alias: 'numeric',
  rightAlign: false,
  allowMinus: false,
  inputmode: 'numeric',
  numericInput: true,
  digits: 0,
  max: 9999999,
};
export const numberMask = new Inputmask(numberMaskOptions);

export const digitsMaskOptions: Inputmask.Options = {
  mask: '999999999',
  placeholder: '',
  inputmode: 'numeric',
};
export const digitsMask = new Inputmask(digitsMaskOptions);

export default {
  apiBaseUrl,
  tokenCookieKey,
  currencyMask,
  currencyMaskOptions,
  numberMask,
  iinMask,
  digitsMask,
};
