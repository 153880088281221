import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "btn__prefix" }
const _hoisted_2 = { class: "loading-btn__spinner" }
const _hoisted_3 = { class: "loading-btn__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CSpinner = _resolveComponent("CSpinner")!
  const _component_CButton = _resolveComponent("CButton")!

  return (_openBlock(), _createBlock(_component_CButton, _mergeProps(_ctx.$props, {
    class: { 'loading-btn': true, 'loading-btn--loading': _ctx.isLoading  },
    disabled: _ctx.isLoading
  }), {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _renderSlot(_ctx.$slots, "prefix")
      ]),
      _createElementVNode("span", _hoisted_2, [
        _createVNode(_component_CSpinner)
      ]),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.text), 1)
    ]),
    _: 3
  }, 16, ["class", "disabled"]))
}