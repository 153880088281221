import { AxiosPromise } from 'axios';
import api from '@/repositories/api';
import { DataResponse, ResourceCollectionRequest } from '@/types/api/common';

export default {
  getList(req?: ResourceCollectionRequest<any>): AxiosPromise<DataResponse<Record<string, any>[]>> {
    if (req) {
      req.addSnakeCaseFilterIgnore('subdivision_id');
    }
    return api.get(`/admin/fixed-assets?${req?.forUrl || ''}`);
  },
  get(id: string): AxiosPromise<Record<string, any>> {
    return api.get(`/admin/fixed-assets/${id}`);
  },
};
