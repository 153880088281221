import printJS from 'print-js';
import { FixedAssetModel } from '@/types/forms/fixed-assets';

export default function printFixedAssetsQrs(
  fixedAssets: FixedAssetModel[],
  opts: {
    onError?: (error: any, xmlHttpRequest?: XMLHttpRequest) => void,
    onLoadingStart?: () => void,
    onLoadingEnd?: () => void,
  },
) {
  try {
    const baseContainer = document.createElement('div');

    fixedAssets.forEach((e) => {
      const itemContainer = document.createElement('div');
      const pageBreak = document.createElement('div');
      pageBreak.setAttribute('style', [
        'clear: both',
        'page-break-after: always',
      ].join(';'));

      const img = document.createElement('img');
      img.src = e.qrCodeUrl;
      img.setAttribute('style', [
        'height: 95%',
        'flex-shrink: 0',
        'transform: rotate(-90deg)',
      ].join(';'));

      const textContainer = document.createElement('div');
      textContainer.setAttribute('style', [
        'text-align: center',
        'writing-mode: vertical-rl',
        'transform: scale(-1, -1)',
        'font-family: arial, sans-serif',
        'line-height: 1.4',
        'font-weight: bold',
        'height: 100%',
      ].join(';'));

      const codeContainer = document.createElement('div');
      codeContainer.setAttribute('style', [
        'font-size: 60px',
      ].join(';'));
      codeContainer.innerText = e.code || 'Н/Д';
      textContainer.appendChild(codeContainer);

      const inventoryNumberContainer = document.createElement('div');
      inventoryNumberContainer.setAttribute('style', [
        'font-size: 60px',
      ].join(';'));
      inventoryNumberContainer.innerText = `ИН: ${e.inventoryNumber || 'Н/Д'}`;
      textContainer.appendChild(inventoryNumberContainer);

      const nameContainer = document.createElement('div');
      nameContainer.setAttribute('style', [
        'font-size: 40px',
      ].join(';'));
      nameContainer.innerText = e.name;
      textContainer.appendChild(nameContainer);

      itemContainer.appendChild(img);
      itemContainer.appendChild(textContainer);

      itemContainer.setAttribute('style', [
        'display: flex',
        'align-items: center',
        'width: 100%',
        'height: 700px',
        'padding-left: 40px',
        'padding-top: 20px',
        'padding-bottom: 20px',
      ].join(';'));

      baseContainer.appendChild(itemContainer);
      baseContainer.appendChild(pageBreak);
    });

    printJS({
      printable: baseContainer.outerHTML,
      type: 'raw-html',
      onLoadingStart: opts.onLoadingStart,
      onLoadingEnd: opts.onLoadingEnd,
      onError: opts.onError,
    });
  } catch (e) {
    console.error(e);
    if (opts.onError) opts.onError(e);
  }
}
