
import { Options, Vue } from 'vue-class-component';
import Cookie from 'js-cookie';
import moment from 'moment';
import auth from '@/repositories/api/auth';
import { tokenCookieKey } from '@/app.config';
import logo from '@/assets/images/logo-orange.svg';
import AppSidebarNav from '@/components/AppSidebarNav.vue';
import apiAppInfo from '@/repositories/api/app-info';

@Options({
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
})
export default class AppSidebar extends Vue {
  logo = logo;
  onecIntegrationLastSync: string | null = null;

  get sidebarUnfoldable() {
    return this.$store.state.sidebarUnfoldable;
  }

  get sidebarVisible() {
    return this.$store.state.sidebarVisible;
  }

  created() {
    this.getSyncDate();
  }

  onLogoutBtnClick(event: any) {
    auth.logout()
      .then((res) => {
        if (res.status === 204) {
          Cookie.remove(tokenCookieKey);
          window.location.href = '/auth/login';
        } else {
          this.$store.dispatch('showAlertError', 'Произошла ошибка');
        }
      })
      .catch((error: any) => {
        console.error(error);
        this.$store.dispatch('showAlertError', 'Произошла ошибка');
      });
  }

  async getSyncDate() {
    try {
      const response = await apiAppInfo.get();
      if (response.data.onec_integration_last_sync) {
        this.onecIntegrationLastSync = moment(response.data.onec_integration_last_sync)
          .format('DD.MM.YYYY HH:mm:ss');
      }
    } catch (e) {
      console.error(e);
      this.$store.dispatch('showAlertError', 'Произошла ошибка при загрузке');
    }
  }
}
