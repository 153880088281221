
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'AppAlert',
  props: {
    /**
     * Sets the color context of the component to one of CoreUI’s themed colors.
     *
     * @values 'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark', 'light'
     */
    color: {
      type: String,
    },
    isLoading: {
      type: Boolean,
    },
    text: {
      type: String,
      required: true,
    },
    /**
     * Size the component small or large.
     *
     * @values 'sm', 'lg'
     */
    size: {
      type: String,
    },
  },
})
export default class AppAlert extends Vue {
  readonly isLoading!: string | null;
}
