import { camelCase, snakeCase } from 'change-case';
import Inputmask from 'inputmask';
import { currencyMaskOptions } from '@/app.config';

const formatCurrency = (value: number): string => Inputmask
  .format(value.toString(), currencyMaskOptions);

interface DataApiResolverCgf {
  transformValues?: string[],
  removeFields?: string[],
}

const defaultDataApiResolverCgf: DataApiResolverCgf = {
  transformValues: [],
  removeFields: [],
};

const resolveDataApiResolver = (
  data: Record<string, any>,
  transformer: (input: string) => string,
  cfg: DataApiResolverCgf,
): Record<string, any> => {
  const newData: any = {};
  Object.keys(data).forEach((key: string) => {
    if (cfg.removeFields && cfg.removeFields.indexOf(key) !== -1) return;
    let value = data[key];
    if (cfg.transformValues && cfg.transformValues.indexOf(key) !== -1) {
      value = transformer(value);
    }
    newData[transformer(key)] = value;
  });
  return newData;
};
const resolveDataFromApi = <T>(
  data: Record<string, any>,
  cfg: DataApiResolverCgf = defaultDataApiResolverCgf,
): T => resolveDataApiResolver(data, camelCase, cfg) as T;
const resolveDataForApi = (
  data: Record<string, any>,
  cfg: DataApiResolverCgf = defaultDataApiResolverCgf,
): Record<string, any> => resolveDataApiResolver(data, snakeCase, cfg);

export default {
  resolveDataFromApi,
  resolveDataForApi,
  formatCurrency,
};
