import {
  Action,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import { SubdivisionModel } from '@/types/forms/subdivisions';
import subdivisionsApi from '@/repositories/api/subdivisions';
import helpers from '@/helpers';
import { SelectOptions } from '@/types/form';

export type SubdivisionListProp = SubdivisionModel[] | null;
export type SubdivisionsOptions = SelectOptions<string>;

@Module
export default class Subdivision extends VuexModule {
  list: SubdivisionListProp = null;

  get subdivisions(): SubdivisionListProp {
    return this.list;
  }

  get subdivisionsOptions(): SubdivisionsOptions {
    if (!this.list) return [];
    return this.list.map((e) => ({
      value: e.id,
      label: e.name,
    }));
  }

  @Mutation
  SET_LIST(list: SubdivisionListProp): void {
    this.list = list;
  }

  @Action({ commit: 'SET_LIST' })
  async loadSubdivisions(): Promise<SubdivisionListProp> {
    try {
      const res = await subdivisionsApi.getList();
      if (!res.data.data) throw new Error('error');
      return res.data.data.map(
        (e) => helpers.resolveDataFromApi<SubdivisionModel>(e),
      );
    } catch {
      await this.context.dispatch(
        'showAlertError', 'Произошла ошибка при загрузке приложения',
      );
      return null;
    }
  }
}
