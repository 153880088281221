import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "px-0 ps-3 mb-0 mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CFormFeedback = _resolveComponent("CFormFeedback")!

  return (_openBlock(), _createBlock(_component_CFormFeedback, {
    valid: _ctx.valid,
    invalid: _ctx.invalid
  }, {
    default: _withCtx(() => [
      (_ctx.listText)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.listText.length > 1)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listText, (listTextItem) => {
                    return (_openBlock(), _createElementBlock("li", { key: listTextItem }, _toDisplayString(listTextItem), 1))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(_ctx.listText[0]), 1)
                ], 64))
          ], 64))
        : _renderSlot(_ctx.$slots, "default", { key: 1 })
    ]),
    _: 3
  }, 8, ["valid", "invalid"]))
}