
import { Options, Vue } from 'vue-class-component';
import AppButton from '@/components/AppButton.vue';

@Options({
  name: 'ConfirmationModal',
  components: { AppButton },
  emits: ['close', 'confirm'],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Подтвердите действие',
    },
    text: {
      type: String,
    },
  },
})
export default class ConfirmationModal extends Vue {
}
