
import { Options, Vue } from 'vue-class-component';
import VueSelect, { OptionConsumer } from 'vue-select';
import { PropType } from 'vue';
import AppFormFeedback from '@/components/AppFormFeedback.vue';

@Options({
  name: 'AppSelect',
  components: { AppFormFeedback },
  props: {
    ...VueSelect.props,
    modelValue: {
      type: [String, Number, null],
      required: false,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    reduce: {
      type: Function as PropType<OptionConsumer>,
      default: (e: any) => e.value,
    },
    errors: {
      type: Array as PropType<string[]>,
    },
  },
  emits: ['update:modelValue'],
  watch: {
    localValue() {
      this.$emit('update:modelValue', this.localValue);
    },
    modelValue() {
      this.localValue = this.modelValue;
    },
  },
})
export default class AppSelect extends Vue {
  readonly errors!: string[] | null | undefined;
  readonly modelValue!: any;
  localValue: any = this.modelValue;

  get isInvalid(): boolean {
    return Boolean(this.errors?.length);
  }
}
