import { AxiosPromise } from 'axios';
import api from '@/repositories/api';
import { PaginationResponse, ResourceCollectionRequest } from '@/types/api/common';

export default {
  getList(req: ResourceCollectionRequest<any>)
    : AxiosPromise<PaginationResponse<Record<string, any>[]>> {
    req.addSnakeCaseFilterIgnore('subdivision_id');
    return api.get(`/admin/users?${req.forUrl}`);
  },
  get(id: number): AxiosPromise<Record<string, any>> {
    return api.get(`/admin/users/${id}`);
  },
  patch(id: number, data: any): AxiosPromise<Record<string, any>> {
    return api.patch(`/admin/users/${id}`, data);
  },
  put(id: number, data: any): AxiosPromise<Record<string, any>> {
    return api.put(`/admin/users/${id}`, data);
  },
  create(data: any): AxiosPromise<Record<string, any>> {
    return api.post('/admin/users', data);
  },
};
