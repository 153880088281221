import { createApp, h } from 'vue';
import CoreuiVue from '@coreui/vue';
import CIcon from '@coreui/icons-vue';
import VCalendar from 'v-calendar';
import { BIconEyeFill, BIconEyeSlashFill } from 'bootstrap-icons-vue';
import VueCropperJs from 'vue-cropperjs';
import VueEditor from 'vue3-editor';
import CKEditor from '@ckeditor/ckeditor5-vue';
import vSelect from 'vue-select';
import App from './App.vue';
import config from '@/plugins/config.plugin';
import helpers from '@/plugins/helpers.plugin';
import trans from '@/plugins/trans.plugin';
import router from './router';
import store from './store';
import 'v-calendar/dist/style.css';
import 'cropperjs/dist/cropper.min.css';
import 'vue-select/dist/vue-select.css';

import icons from '@/assets/icons';
import AppArrowDownIcon from '@/assets/icons/AppArrowDownIcon.vue';
import AppClearButton from '@/components/AppClearButton.vue';

import '@/validator-messages';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
vSelect.props.components.default = () => ({
  OpenIndicator: {
    render: () => h(AppArrowDownIcon),
  },
  Deselect: {
    render: () => h(AppClearButton),
  },
});

const app = createApp(App);
app.use(config);
app.use(helpers);
app.use(trans);
app.use(CKEditor);
app.use(VCalendar);
app.component('BIconEyeFill', BIconEyeFill);
app.component('BIconEyeSlashFill', BIconEyeSlashFill);
app.component('VueCropper', VueCropperJs);
app.component('VueEditor', VueEditor);
app.component('VSelect', vSelect);
app.use(store);
app.use(router);
app.provide('icons', icons);
app.use(CoreuiVue);
app.component('CIcon', CIcon);
app.mount('#app');
