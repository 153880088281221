<template>
  <div class="d-flex justify-content-end align-items-center user-bar">
    <div class="d-flex justify-content-end align-items-center" v-if="!profile">
      <CSpinner color="primary"/>
    </div>
    <template v-else>
      <div class="user-bar__name">{{ profile.firstName }} {{ profile.lastName }}</div>
      <CAvatar :src="avatar" size="md"/>
    </template>
  </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import { mapGetters } from 'vuex';
import avatar from '@/assets/images/avatars/blank.png';

@Options({
  name: 'AppHeaderDropdownAccnt',
  computed: mapGetters({
    profile: 'myProfile',
  }),
})
export default class AppHeaderDropdownAccnt extends Vue {
  avatar = avatar
}
</script>
