
import { Options, Vue } from 'vue-class-component';
import AppButton from '@/components/AppButton.vue';
import FastQrCodePrint from '@/components/FastQrCodePrint.vue';
import MassQrCodePrint from '@/components/MassQrCodePrint.vue';

@Options({
  name: 'QrPrint',
  components: {
    MassQrCodePrint,
    FastQrCodePrint,
    AppButton,
  },
})
export default class QrPrint extends Vue {
  showFastPrintModal = false;
  showMassPrint = false;

  created(): void {
    this.$store.commit('SET_HEADER_TITLE', 'Печать QR');
  }
}
