import { UserRole } from '@/types/forms/users';

interface NavItem {
  component: string,
  name: string,
  to: string,
  icon: string,
  userRoles: UserRole[],
  items: NavItem[],
}

export default [
  {
    component: 'CNavTitle',
    name: 'Меню',
  },
  {
    component: 'CNavItem',
    name: 'Пользователи',
    to: '/users',
    icon: 'cil-group',
    userRoles: ['admin'],
  },
  {
    component: 'CNavItem',
    name: 'Печать QR',
    to: '/qr-print',
    icon: 'cil-qr-code',
    userRoles: ['accountant'],
  },
] as NavItem[];
