import { AxiosPromise } from 'axios';
import { AuthLoginRequest, AuthLoginResponse } from '@/types/api/auth';
import api from '@/repositories/api';

export default {
  login(params: AuthLoginRequest): AxiosPromise<AuthLoginResponse> {
    return api.post('/admin/auth/sign-in', params);
  },
  logout(): AxiosPromise {
    return api.post('/admin/auth/logout');
  },
};
