
import { Options, Vue } from 'vue-class-component';
import { CContainer } from '@coreui/vue';
import AppFooter from '@/components/AppFooter.vue';
import AppHeader from '@/components/AppHeader.vue';
import AppSidebar from '@/components/AppSidebar.vue';

@Options({
  name: 'CabinetLayout',
  components: {
    AppFooter,
    AppHeader,
    AppSidebar,
    CContainer,
  },
})
export default class CabinetLayout extends Vue {
  created() {
    this.$store.dispatch('loadSubdivisions');
  }
}
