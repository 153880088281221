import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "accordion-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CAccordionHeader = _resolveComponent("CAccordionHeader")!
  const _component_CAccordionBody = _resolveComponent("CAccordionBody")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CAccordionHeader, null, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "header")
      ]),
      _: 3
    }),
    _createVNode(_component_CAccordionBody, null, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "body")
      ]),
      _: 3
    })
  ]))
}