
import { Options, Vue } from 'vue-class-component';
import AppAlert from '@/components/AppAlert.vue';
import router from '@/router';
import { AppRouter403Error } from '@/types/app';
import Forbidden403 from '@/views/errors/Forbidden403.vue';

@Options({
  components: { Forbidden403, AppAlert },
})
export default class App extends Vue {
  routerReady = false;
  forbidden403 = false;

  created() {
    router.onError(this.onRouterError);
    this.$router.isReady().then(() => { this.routerReady = true; });
  }

  onRouterError(error: any) {
    if (error instanceof AppRouter403Error) {
      this.forbidden403 = true;
    }
  }

  async goToMainPage() {
    this.forbidden403 = false;
    await this.$router.replace('/');
    this.routerReady = true;
  }
}
