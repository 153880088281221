
import { Options, Vue } from 'vue-class-component';
import { mapGetters } from 'vuex';
import apiUsers from '@/repositories/api/users';
import { UserModel, userRolesOptions } from '@/types/forms/users';
import AppInput from '@/components/AppInput.vue';
import AppSelect from '@/components/AppSelect.vue';
import { phoneMask, maskPhoneString } from '@/app.config';
import AppButton from '@/components/AppButton.vue';
import { ResourceCollectionRequest } from '@/types/api/common';
import { SubdivisionListProp, SubdivisionsOptions } from '@/store/api/Subdivision';

const usersListSearchEmpty = {
  name: '',
  role: '',
  login: '',
  withBlocked: true,
  subdivisionId: '',
};

interface ResponsePagination {
  from: number,
  to: number,
  lastPage: number,
  total: number,
}

@Options({
  name: 'UsersList',
  components: { AppButton, AppSelect, AppInput },
  computed: mapGetters(['subdivisionsOptions', 'subdivisions']),
})
export default class UsersList extends Vue {
  readonly maskPhoneString = maskPhoneString;
  readonly phoneMask = phoneMask;
  readonly userRolesOptions = userRolesOptions;
  readonly subdivisionsOptions!: SubdivisionsOptions;
  readonly subdivisions!: SubdivisionListProp;
  blockedUser: UserModel | null = null;
  isLoading = true;
  isBlockLoading = false;
  users: UserModel[] = [];
  successSave = false;
  successDelete = false;
  filter: typeof usersListSearchEmpty = { ...usersListSearchEmpty };
  pagination: ResponsePagination | null = null;

  get paginationPage(): number {
    let page = parseInt(this.$route.query?.page?.toString() ?? '', 0);
    if (Number.isNaN(page)) {
      page = 1;
    }
    return page;
  }

  async setPaginationPage(page: number) {
    await this.$router.replace({ query: { page: page.toString() } });
  }

  async goToPage(page: number) {
    await this.setPaginationPage(page);
    await this.loadUsers();
  }

  goNextPage(): void {
    this.goToPage(this.paginationPage + 1);
  }

  goPrevPage(): void {
    this.goToPage(this.paginationPage - 1);
  }

  checkSuccessMessage(): void {
    if (Object.keys(this.$route.query).indexOf('success-save') === -1) return;
    this.successSave = true;
    this.$router.replace(
      this.$route.fullPath.replace(/success-save=?[^&]*&?/, ''),
    );
  }

  applySearch() {
    this.goToPage(1);
  }

  resetSearch() {
    this.filter = { ...usersListSearchEmpty };
    this.goToPage(1);
  }

  created(): void {
    this.$store.commit('SET_HEADER_TITLE', 'Пользователи');
    this.checkSuccessMessage();
    this.loadUsers();
  }

  async blockUser(): Promise<void> {
    if (!this.blockedUser) return;
    try {
      this.isBlockLoading = true;
      await apiUsers.patch(
        this.blockedUser.id,
        {
          blocked: !this.blockedUser.blocked,
        },
      );
      this.blockedUser = null;
      this.loadUsers();
    } catch (e) {
      this.$store.dispatch('showAlertError', 'Произошла ошибка при запросе');
    } finally {
      this.isBlockLoading = false;
    }
  }

  async loadUsers(): Promise<void> {
    try {
      this.isLoading = true;
      const response = await apiUsers.getList(new ResourceCollectionRequest({
        page: this.paginationPage,
        filter: this.filter,
      }));
      this.pagination = this.$helpers.resolveDataFromApi<ResponsePagination>(response.data.meta);
      this.users = response.data.data.map(
        (e) => this.$helpers.resolveDataFromApi<UserModel>(e, {
          transformValues: ['role'],
        }),
      );
    } catch (e) {
      console.error(e);
      this.$store.dispatch('showAlertError', 'Произошла ошибка при загрузке');
    } finally {
      this.isLoading = false;
    }
  }

  getUserSubdivisionName(user: UserModel): string {
    return user.subdivisionId
      ? this.subdivisions?.find((e) => e.id === user.subdivisionId)?.name || '-'
      : '-';
  }
}
