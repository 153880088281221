
import { Options, setup, Vue } from 'vue-class-component';
import {
  computed,
  inject,
  Ref,
  ref,
} from 'vue';

@Options({
  name: 'AppAccordionItem',
  props: {
    itemKey: {
      type: String,
      default: () => Math.random().toString(36).substr(2, 9),
    },
  },
  provide() {
    return {
      visible: computed(() => this.visible),
      toggleVisibility: this.toggleVisibility,
    };
  },
  inject: [
    'addActiveItemKey',
    'removeActiveItemKey',
    'activeKeys',
  ],
})
export default class AppAccordionItem extends Vue {
  readonly addActiveItemKey!: (key: string) => void;
  readonly removeActiveItemKey!: (key: string) => void;
  itemKey!: string;
  visible: boolean = setup(() => {
    const activeKeys = (inject('activeKeys') as Ref<string[]>).value;
    return ref(activeKeys.includes(this.itemKey));
  });
  toggleVisibility() {
    this.visible = !this.visible;
    if (this.visible) {
      this.addActiveItemKey(this.itemKey);
    } else {
      this.removeActiveItemKey(this.itemKey);
    }
  }
}
