import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CModalTitle = _resolveComponent("CModalTitle")!
  const _component_CModalHeader = _resolveComponent("CModalHeader")!
  const _component_CModalBody = _resolveComponent("CModalBody")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_CModalFooter = _resolveComponent("CModalFooter")!
  const _component_CModal = _resolveComponent("CModal")!

  return (_openBlock(), _createBlock(_component_CModal, {
    visible: _ctx.visible,
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_CModalHeader, null, {
        default: _withCtx(() => [
          _createVNode(_component_CModalTitle, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.text)
        ? (_openBlock(), _createBlock(_component_CModalBody, { key: 0 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.text), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_CModalFooter, {
        class: _normalizeClass({ 'border-top-0': !_ctx.text })
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AppButton, {
            color: "primary",
            text: "Закрыть",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
          }),
          _createVNode(_component_AppButton, {
            color: "light",
            text: "Подтвердить",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('confirm')))
          })
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }, 8, ["visible"]))
}