import { createStore } from 'vuex';
import Alert from '@/store/modules/Alert';
import Profile from '@/store/modules/Profile';
import Header from '@/store/modules/Header';
import Subdivision from '@/store/api/Subdivision';

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible;
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable;
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value;
    },
  },
  actions: {},
  modules: {
    alert: Alert,
    profile: Profile,
    header: Header,
    subdivision: Subdivision,
  },
});
