
import { PropType } from 'vue';
import { Options, Vue } from 'vue-class-component';
import Inputmask from 'inputmask';
import AppCrossIcon from '@/assets/icons/AppCrossIcon.vue';
import AppClearButton from '@/components/AppClearButton.vue';
import AppFormFeedback from '@/components/AppFormFeedback.vue';

export type AppInputType = 'text' | 'number';

@Options({
  name: 'AppInput',
  components: { AppFormFeedback, AppClearButton, AppCrossIcon },
  props: {
    modelValue: {
      type: [String, Number],
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String as PropType<AppInputType>,
      required: false,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    mask: {
      type: Object as PropType<Inputmask.Instance>,
      required: false,
      default: null,
    },
    autoUnmask: {
      type: Boolean,
      required: false,
      default: true,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
    errors: {
      type: Array as PropType<string[]>,
    },
    autofocus: {
      type: String,
    },
  },
  emits: ['update:modelValue'],
  watch: {
    localValue() {
      this.$emit('update:modelValue', this.unmaskedValue);
      this.resolveClearBtnState();
    },
    modelValue() {
      this.localValue = this.modelValue;
    },
  },
})
export default class AppInput extends Vue {
  readonly errors!: string[] | null | undefined;
  readonly clearable!: boolean;
  readonly mask!: Inputmask.Instance | null;
  readonly modelValue!: string;
  readonly type!: AppInputType;
  readonly autoUnmask!: boolean;
  maskInput: Inputmask.Instance | null = null;
  localValue: any = this.modelValue;
  showClearBtn = false;

  $refs!: {
    input: HTMLFormElement
  }

  mounted(): void {
    if (this.mask != null) {
      this.maskInput = this.mask.mask(this.$refs.input.$el);
    }
    this.resolveClearBtnState();
  }

  get isInvalid(): boolean {
    return Boolean(this.errors?.length);
  }

  get unmaskedValue(): string|number {
    let value = this.localValue;
    if (this.maskInput != null && this.autoUnmask) {
      this.maskInput.setValue(value);
      value = this.maskInput.unmaskedvalue();
    }
    if (this.type === 'number') {
      value = parseFloat(value);
      if (Number.isNaN(value)) {
        value = 0;
      }
    }
    return value;
  }

  resolveClearBtnState() {
    if (!this.clearable) return;
    const val = this.unmaskedValue;
    if (typeof val === 'number') {
      this.showClearBtn = val !== 0;
    } else {
      this.showClearBtn = (this.unmaskedValue as string).trim().length > 0;
    }
  }

  clear() {
    this.localValue = '';
  }
}
