import {
  Action,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import me from '@/repositories/api/me';
import helpers from '@/helpers';
import { UserModel } from '@/types/forms/users';

@Module
export default class Profile extends VuexModule {
  profile: UserModel | null = null

  get myProfile(): UserModel | null {
    return this.profile;
  }

  @Mutation
  SET_PROFILE(profile: UserModel | null): void {
    this.profile = profile;
  }

  @Action({ commit: 'SET_PROFILE' })
  logout(): null {
    return null;
  }

  @Action({ commit: 'SET_PROFILE' })
  async getProfile(): Promise<UserModel | null> {
    try {
      const response = await me.get();
      if (response.status === 200) {
        return helpers.resolveDataFromApi<UserModel>(response.data, {
          transformValues: ['role'],
        });
      }
      this.context.dispatch('showAlertError', 'Произошла ошибка при загрузке приложения');
      return null;
    } catch {
      this.context.dispatch('showAlertError', 'Произошла ошибка при загрузке приложения');
      return null;
    }
  }

  @Action
  async initProfile(): Promise<void> {
    await this.context.dispatch('getProfile');
  }
}
