
import { Options, Vue } from 'vue-class-component';
import { computed, PropType } from 'vue';

@Options({
  name: 'AppAccordion',
  props: {
    activeKeys: {
      type: Array as PropType<string[]>,
      default: [],
    },
  },
  emits: ['update:activeKeys'],
  provide() {
    return {
      addActiveItemKey: this.addActiveItemKey,
      removeActiveItemKey: this.removeActiveItemKey,
      activeKeys: computed(() => this.activeKeys),
    };
  },
})
export default class AppAccordion extends Vue {
  activeKeys!: string[];

  addActiveItemKey(key: any) {
    this.$emit('update:activeKeys', [...this.activeKeys, key]);
  }
  removeActiveItemKey(key: any) {
    this.$emit('update:activeKeys', this.activeKeys.filter((e) => e !== key));
  }
}
