
import { Options, Vue } from 'vue-class-component';
import Cookie from 'js-cookie';
import { useForm } from '@/utils/forms';
import { AuthLoginRequest } from '@/types/api/auth';
import auth from '@/repositories/api/auth';
import { phoneMask, tokenCookieExpiresInDays, tokenCookieKey } from '@/app.config';
import { ResponseStatus } from '@/types/api/common';
import AppInput from '@/components/AppInput.vue';
import AppButton from '@/components/AppButton.vue';
import store from '@/store';

@Options({
  name: 'Login',
  components: { AppButton, AppInput },
  watch: {
    form: {
      deep: true,
      handler(): void {
        this.credentialsInvalid = false;
      },
    },
  },
})
export default class Login extends Vue {
  phoneMask = phoneMask;
  hiddenPassword = true;
  form = useForm<AuthLoginRequest>({
    login: {
      value: '',
    },
    password: {
      value: '',
    },
  });
  isLoading = false;
  credentialsInvalid = false;

  get isFormValid(): boolean {
    return this.form.login.value.trim().length > 0
        && this.form.password.value.length > 0;
  }

  async handleSend() {
    if (!this.isFormValid) {
      return;
    }
    try {
      this.isLoading = true;
      const response = await auth.login({
        login: this.form.login.value,
        password: this.form.password.value,
      });
      if (response.data.token) {
        this.saveToken(response.data.token);
        await this.$store.dispatch('initProfile');
        await this.$router.push('/');
      } else {
        this.$store.dispatch('showAlertError', 'Произошла ошибка');
      }
    } catch (error) {
      if (error.response && error.response.status === ResponseStatus.UNAUTHORIZED) {
        this.credentialsInvalid = true;
      } else {
        this.$store.dispatch('showAlertError', 'Произошла ошибка');
      }
    } finally {
      this.isLoading = false;
    }
  }

  saveToken(token: string): void {
    Cookie.set(tokenCookieKey, token, {
      expires: +tokenCookieExpiresInDays as number,
      secure: true,
      sameSite: 'Lax',
    });
  }
}
