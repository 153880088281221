import { SelectOptions } from '@/types/form';
import trans from '@/trans';
import { IndividualModel } from '@/types/forms/individuals';

export type UserRole = 'admin' | 'accountant' | 'responsiblePerson';

// export const userRolesTrans: Record<UserRole, string> = {
//   admin: 'Админ',
//   accountant: 'Бухгалтер',
//   responsiblePerson: 'МОЛ',
// };

export const userRolesOptions: SelectOptions<UserRole> = [
  {
    value: 'admin',
    label: trans._('user.roles.admin'),
  },
  {
    value: 'accountant',
    label: trans._('user.roles.accountant'),
  },
  {
    value: 'responsiblePerson',
    label: trans._('user.roles.responsiblePerson'),
  },
];

export interface UserModel {
  id: number,
  firstName: string,
  lastName: string | null,
  login: string,
  email: string,
  blocked: boolean,
  role: UserRole,
  subdivisionId: string | null,
  individual: IndividualModel | null,
}
