import { App } from 'vue';
import trans from '@/trans';

export default {
  install(app: App): void {
    app.config.globalProperties.$trans = trans;
  },
};

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $trans: typeof trans;
  }
}
