import { flatten } from 'flat';

const dict: Record<string, string> = flatten({
  user: {
    roles: {
      admin: 'Администратор',
      accountant: 'Бухгалтер',
      responsiblePerson: 'МОЛ',
    },
  },
});

const _ = (key: string): string => dict[key];

export default {
  _,
};
