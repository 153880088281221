import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CFormInput = _resolveComponent("CFormInput")!
  const _component_CInputGroup = _resolveComponent("CInputGroup")!
  const _component_AppClearButton = _resolveComponent("AppClearButton")!
  const _component_AppFormFeedback = _resolveComponent("AppFormFeedback")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'clearable-form-control': _ctx.clearable })
  }, [
    _createVNode(_component_CInputGroup, {
      class: _normalizeClass({ 'is-invalid': _ctx.isInvalid })
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "prefix"),
        _createVNode(_component_CFormInput, _mergeProps({
          ref: "input",
          type: _ctx.type,
          modelValue: _ctx.localValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
          disabled: _ctx.disabled,
          invalid: _ctx.isInvalid,
          placeholder: _ctx.placeholder
        }, { autofocus: _ctx.autofocus }), null, 16, ["type", "modelValue", "disabled", "invalid", "placeholder"]),
        _renderSlot(_ctx.$slots, "postfix")
      ]),
      _: 3
    }, 8, ["class"]),
    (_ctx.clearable && _ctx.showClearBtn)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          type: "button",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clear && _ctx.clear(...args))),
          class: "clearable-form-control__btn"
        }, [
          _createVNode(_component_AppClearButton)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_AppFormFeedback, {
      invalid: "",
      "list-text": _ctx.errors
    }, null, 8, ["list-text"])
  ], 2))
}